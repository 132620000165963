import { useEffect, useState } from 'react';

import { Flex, List, Typography } from 'antd';

import { useGetQuestionsHistory } from '@/api/batch_process';
import { IPagination } from '@/pages/app/dashboard/video-intelligence-on-demand';

import QAHistoryCollapsibleItem from './QAHistoryCollapsibleItem';

type TProps = {
	item: any;
	activeKey: 'question' | 'history';
};

export default function QAHistoryTab({ item, activeKey }: TProps) {
	const [pagination, setPagination] = useState<IPagination>({
		page: 1,
		pageCount: 1,
		total: 0,
		limit: 10,
	});

	const {
		data: questionsHistoryData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetQuestionsHistory({
		page: pagination.page,
		limit: pagination.limit,
		userId: Number(localStorage?.getItem('sv_extension_uid')),
		url: item?.url,
		enabled: activeKey === 'history',
	});

	useEffect(() => {
		if (questionsHistoryData?.data?.meta) {
			setPagination((cur) => ({
				...cur,
				page: questionsHistoryData?.data?.meta?.current || 1,
				total: questionsHistoryData?.data?.meta?.total || 0,
			}));
		}
	}, [questionsHistoryData]);

	return (
		<Flex vertical gap={16}>
			<List
				loading={isLoading || isFetching}
				pagination={{
					position: 'bottom',
					align: 'center',
					...pagination,
					onChange: (page: number, pageSize: number) => {
						setPagination((cur) => ({
							...cur,
							page,
							limit: pageSize,
						}));
					},
				}}
				dataSource={questionsHistoryData?.data?.data ?? []}
				renderItem={(item: any, index) => (
					<QAHistoryCollapsibleItem item={item} key={`qa-item-${index}`} />
				)}
			/>
			{/* <Table
        size="small"
        style={{ width: "100%" }}
        rowKey={"question"}
        bordered
        loading={isLoading || isFetching}
        columns={[
          {
            title: t("question"),
            dataIndex: "question",
            key: "question",
            // width: 200,
            minWidth: 120,
            ellipsis: true,
            render: (_: any, record: any) => {
              return (
                <Tooltip title={record?.question}>{record?.question}</Tooltip>
              );
            },
          },
          {
            title: t("answer"),
            dataIndex: "answer",
            key: "answer",
            ellipsis: true,
            render: (_: any, record: any) => {
              return <Tooltip title={record?.answer}>{record?.answer}</Tooltip>;
            },
          },
          {
            title: t("created_at"),
            ellipsis: true,
            // sorter: true,
            dataIndex: "created_at",
            key: "created_at",
            width: 100,
            minWidth: 70,
            render: (_: any, record: any) => {
              return record?.created_at
                ? prepareDate(record?.created_at?.toString())
                : null;
            },
          },
        ]}
        dataSource={questionsHistoryData?.data?.data ?? []}
        pagination={{
          total: questionsHistoryData?.data?.meta?.total ?? 1,
          //   showSizeChanger: true,
          current: pagination.page,
          onChange: (page: number, pageSize: number) => {
            setPagination((cur) => ({
              ...cur,
              page,
              limit: pageSize,
            }));
          },
        }}
      /> */}
		</Flex>
	);
}
