import { useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';

import getTimeInSeconds from '@/lib/helpers/getTimeInSeconds';
import prepareTimestampStr from '@/lib/helpers/prepareTimestampStr';

type TProps = {
	item: {
		Emoji: string;
		Sentence: string;
		Extract: string;
		Timestamp: string;
	};
	videoSeekToTime: (seconds: number) => void;
	videoDuration: number;
};

export default function InsightCollapsableItem({
	item,
	videoSeekToTime,
	videoDuration,
}: TProps) {
	const [collapsed, setCollpased] = useState(true);
	const seconds = getTimeInSeconds(item?.Timestamp);
	const preparedTimestampStr = prepareTimestampStr(
		videoDuration,
		item?.Timestamp,
	);

	return (
		<List.Item>
			<List.Item.Meta
				avatar={
					<span style={{ display: 'block', marginTop: '5px' }}>
						{item?.Emoji}
					</span>
				}
				title={
					<span
						style={{
							color: '#000',
							fontWeight: 500,
						}}
					>
						{seconds !== null && (
							<Button
								type="link"
								style={{
									padding: '2px 2px',
									marginRight: '4px',
								}}
								onClick={() => videoSeekToTime(seconds)}
							>
								{preparedTimestampStr}
							</Button>
						)}

						{item?.Sentence}

						{item?.Extract && (
							<Button
								type="text"
								size="small"
								icon={collapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
								onClick={() => setCollpased((cur) => !cur)}
								style={{ marginLeft: '0.5rem' }}
								aria-label="Toggle expand/collapse"
							></Button>
						)}
					</span>
				}
				description={
					collapsed ? null : (
						<span
							style={{
								color: '#000',
								fontStyle: 'italic',
								//  fontWeight: 600
							}}
						>
							"{item?.Extract}"
						</span>
					)
				}
			/>
		</List.Item>
	);
}
