import { Button, Tooltip } from 'antd';

import feedbackImg from '@/lib/assets/feedback_orange.png';

import styles from './styles.module.css';

type TProps = {
	setOpenModal: (value: boolean) => void;
};

export default function FeedBackButton({ setOpenModal }: TProps) {
	return (
		<Tooltip title="Leave feedback" placement="left">
			<button
				className={styles.button}
				aria-label="'Leave feedback' button"
				onClick={() => setOpenModal(true)}
			>
				<img
					src={feedbackImg}
					width={50}
					height={40}
					className={styles.image}
					alt="Feedback"
				/>
			</button>
		</Tooltip>
	);
}
