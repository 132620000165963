import {
	BulbOutlined,
	CommentOutlined,
	ExclamationCircleOutlined,
	FileTextOutlined,
	KeyOutlined,
	PlayCircleOutlined,
	QuestionCircleOutlined,
	ReadOutlined,
	TagOutlined,
	UserOutlined,
} from '@ant-design/icons';

export const titles = {
	'key-insights': 'Summary',
	'creator-insight': 'Creator information',
	'video-insight': 'Video insights',
	'brand-mentions': 'Brand mentions',
	'entity-insights': 'Entity insights',
	transcript: 'Transcription',
	comments: 'Top comments',
	actions: 'Actions',
	'brands-entities': 'Brands/Entities',
	topics: 'Topics',
	history: 'Video Analysis History',
	collections: 'Collections',
	'questions-answers': 'Q&A - chat with the video',

	undefined: '',
};

export type TTitle = keyof typeof titles;

// 'Summary' | "ViewerActions" | "Entities" | "transcription"  | "Topics"
//  ["Summary", "Insights"] | ["ViewerActions", "EntityActions", "ControversialTopics"]

export const tabOptions = [
	{
		value: 'key-insights',
		icon: BulbOutlined,
		title: 'Summary',
		// chunk: "Summary",
		chunk: ['Summary', 'Insights'],
	},
	{
		value: 'transcript',
		icon: FileTextOutlined,
		title: 'Transcription',
		chunk: 'transcription',
	},
	{
		value: 'creator-insight',
		icon: UserOutlined,
		title: 'Creator information',
		chunk: 'creator',
	},
	{
		value: 'video-insight',
		icon: PlayCircleOutlined,
		title: 'Video insights',
		chunk: 'video',
	},
	{
		value: 'comments',
		icon: CommentOutlined,
		title: 'Comments',
		chunk: 'comments',
	},
	// {
	// 	value: 'actions',
	// 	icon: ExclamationCircleOutlined,
	// 	title: 'Actions',
	// 	// chunk: "ViewerActions",

	// 	chunk: ['ViewerActions', 'EntityActions', 'ControversialTopics'],
	// },
	{
		value: 'brands-entities',
		icon: TagOutlined,
		title: 'Brands/Entities',
		chunk: 'Entities',
	},
	{
		value: 'questions-answers',
		icon: QuestionCircleOutlined,
		title: 'Q&A - chat with the video',
		chunk: [],
	},
	// {
	// 	value: 'topics',
	// 	icon: ReadOutlined,
	// 	title: 'Topics',
	// 	chunk: 'Topics',
	// },
];
