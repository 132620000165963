import { useContext, useEffect, useState } from 'react';

import { BookOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Space, Spin, Table, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { useGetAllCopilotVideos } from '@/api/batch_process';
import { PageContext } from '@/providers/PageContextProvider';

import { IPagination } from '../video-intelligence-on-demand';
import HistoryItemButton from './HistoryItemButton';
import ShareButton from './ShareButton';
import SwitchFavourites from './SwitchFavourites';
import { columns } from './data';

export default function VideoInsightsCoPilot() {
	const navigate = useNavigate();
	const { subscriptions, subscriptionLoading } =
		useContext(SubscriptionContext);
	const { setPageTitle, openDrawer, setIsCopilotCreditsCounterVisible } =
		useContext(PageContext);
	const [pagination, setPagination] = useState<IPagination>({
		page: 1,
		pageCount: 1,
		total: 0,
		limit: 10,
	});
	const [filterUserInsights, setFilterUserInsights] = useState(
		localStorage?.getItem('sv_show_user_insights') === '1' ? true : false,
	);
	const [onlyFavourites, setOnlyFavourites] = useState(
		localStorage?.getItem('sv_only_favourites') === '1' ? true : false,
	);

	const [sortBy, setSortBy] = useState<'requested_at'>('requested_at');
	const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');

	const { data, isLoading, isFetching, refetch } = useGetAllCopilotVideos({
		page: pagination.page,
		limit: pagination.limit,
		onlyUser: filterUserInsights,
		favourites: onlyFavourites,
		sortBy,
		sortOrder,
	});

	const total = data?.data?.data?.total ?? 1;
	const items = data?.data?.data?.results ?? [];

	useEffect(() => {
		setPageTitle('Video insights co-pilot');
		setIsCopilotCreditsCounterVisible(true);

		return () => {
			setPageTitle('');
			setIsCopilotCreditsCounterVisible(false);
		};
	}, []);

	useEffect(() => {
		refetch();
	}, [filterUserInsights, sortOrder, pagination]);

	useEffect(() => {
		if (!subscriptionLoading && subscriptions && subscriptions?.length > 0) {
			const hasCopilotSubscription = subscriptions.some((sub) =>
				sub?.subscription_name?.toLowerCase()?.includes('co-pilot'),
			);

			if (!hasCopilotSubscription) {
				navigate('/app/dashboard/product-main-menu');
			}
		} else if (
			!subscriptionLoading &&
			subscriptions &&
			subscriptions?.length === 0
		) {
			navigate('/app/dashboard/product-main-menu');
		}
	}, [subscriptions, subscriptionLoading]);

	const onFavouriteChange = () => {
		setOnlyFavourites((prev) => {
			localStorage.setItem('sv_only_favourites', !prev ? '1' : '0');
			return !prev;
		});
		setPagination((cur: any) => ({ ...cur, page: 1 }));
	};

	const onUserInsightsChange = () => {
		setFilterUserInsights((prev) => {
			localStorage.setItem('sv_show_user_insights', !prev ? '1' : '0');
			return !prev;
		});

		setPagination((cur: any) => ({ ...cur, page: 1 }));
	};

	const onTableChange = (__: any, _: any, sorter: any) => {
		const { field = 'requested_at', order = 'ascend' } = sorter;

		if (field) {
			setSortBy(field);
			// setSearchParams((searchParams) => {
			// 	searchParams.set('sortBy', field);
			// 	return searchParams;
			// });
		}

		if (order) {
			setSortOrder(order === 'ascend' ? 'asc' : 'desc');
			// setSearchParams((searchParams) => {
			// 	searchParams.set('sortOrder', order === 'ascend' ? 'asc' : 'desc');
			// 	return searchParams;
			// });
		}
	};

	return (
		<Spin spinning={isLoading || isFetching}>
			<div>
				<Flex
					align="center"
					justify="space-between"
					style={{ marginBottom: '1.5rem' }}
				>
					<h2>Copilot videos history</h2>

					<Space>
						<Tooltip title="Refresh">
							<Button onClick={() => refetch()} icon={<ReloadOutlined />} />
						</Tooltip>
					</Space>
				</Flex>

				<Flex vertical gap={16} style={{ width: '100%' }}>
					<Flex
						gap={8}
						justify="space-between"
						align="center"
						style={{ width: '100%' }}
					>
						<Checkbox
							checked={filterUserInsights}
							onChange={onUserInsightsChange}
						>
							Only show my reports
						</Checkbox>
						<Checkbox
							checked={onlyFavourites}
							onChange={onFavouriteChange}
							style={{ alignSelf: 'flex-start', marginLeft: '10px' }}
						>
							Only show favourites
						</Checkbox>
					</Flex>

					<Table
						loading={isLoading || isFetching}
						// style={{ width: '100%' }}
						onChange={onTableChange}
						dataSource={items}
						columns={[
							...columns,
							{
								title: 'Actions',
								dataIndex: 'actions',
								key: 'actions',
								width: 40,
								ellipsis: false,
								fixed: 'right',
								// flex: true,
								render: (_: any, record: any) => {
									return (
										<Flex
											justify="center"
											align="center"
											style={{ gap: '8px' }}
										>
											<HistoryItemButton
												url={record?.url}
												setDrawerOpen={() =>
													openDrawer('view-copilot-video-details', record)
												}
												disabled={record?.status !== 2}
											/>
											<ShareButton
												url={record?.url}
												disabled={record?.status !== 2}
											/>
											<SwitchFavourites
												isFavorite={record?.favourite}
												id={record?.id}
												disabled={record?.status !== 2}
											/>
										</Flex>
									);
								},
							},
						]}
						rowKey={'id'}
						bordered
						scroll={{ x: 1100 }}
						pagination={{
							total,
							showSizeChanger: true,
							current: pagination.page,
							pageSizeOptions: [10, 25, 50],
							onChange: (page: number, pageSize: number) => {
								setPagination((cur) => ({ ...cur, page, limit: pageSize }));
							},
						}}
					/>
				</Flex>
			</div>
		</Spin>
	);
}
