import { useEffect, useState } from 'react';

import { Button, Flex, Spin, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { useAskQuestion } from '@/api/batch_process';
import Loader from '@/layouts/loaders/loading';

import styles from './styles.module.css';

import TranscriptionText from '../../TranscriptionText';

type TProps = {
	item: any;
};

export default function QuestionTab({ item }: TProps) {
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [isSent, setIsSent] = useState(false);

	const { mutate, isLoading, isSuccess, isError, data, error } =
		useAskQuestion();

	useEffect(() => {
		if (!isLoading && isSuccess) {
			setIsSent(true);
			setAnswer(data?.data?.response || data?.data?.data?.response);
		}

		if (!isLoading && isError) {
			const resError = error as any;
			message.error(
				`Failed to ask question: ${
					resError?.response?.data?.message || resError?.message
				}`,
			);
		}
	}, [isSuccess, isError]);

	const onAskQuestionClick = () => {
		mutate({
			url: item?.url,
			question,
			// userId: Number(localStorage?.getItem("sv_extension_uid")),
		});
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent new line in textarea
			onAskQuestionClick();
			// Handle submission logic here
		}
	};

	const onClearClick = () => {
		setAnswer('');
		setQuestion('');
		setIsSent(false);
	};

	return (
		<Flex vertical gap={16}>
			<Flex gap={8} className={styles.wrapper}>
				<TextArea
					placeholder={'Ask any question about the transcript here...'}
					autoSize={{ minRows: 5, maxRows: 7 }}
					value={question}
					onKeyDown={handleKeyDown}
					onChange={(e) => setQuestion(e.target.value)}
					readOnly={isSent || isLoading}
					autoFocus
				/>
				<Flex
					align="center"
					justify="center"
					gap={8}
					className={styles.buttonsWrapper}
				>
					<Button
						type="primary"
						onClick={onAskQuestionClick}
						// loading={isLoading}
						disabled={question.trim().length === 0 || isLoading || isSent}
						style={{ width: '100%' }}
					>
						Ask a question
					</Button>
					<Button
						onClick={onClearClick}
						style={{ width: '100%' }}
						disabled={question.length === 0}
					>
						Clear
					</Button>
				</Flex>
			</Flex>

			{isLoading && <Spin />}
			<TranscriptionText text={answer} searchTerm={''} />
		</Flex>
	);
}
