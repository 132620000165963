import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

import styles from './style.module.css';

const HistoryItemButton = ({
	url,
	setDrawerOpen,
	disabled = false,
}: {
	url: string;
	setDrawerOpen: (isOpen: boolean) => void;
	disabled?: boolean;
}) => {
	const toggleIcon = () => {
		// setHistoryItemUrl(url);
		setDrawerOpen(true);
	};

	return (
		<Tooltip title={'View insights'} mouseEnterDelay={1}>
			<Button
				// variant="outlined"
				size="middle"
				// size="small"
				onClick={toggleIcon}
				style={{ fontSize: '18px', padding: '6px', height: 'unset' }}
				className={styles.eyeHover}
				disabled={disabled}
			>
				<EyeOutlined />
			</Button>
		</Tooltip>
	);
};

export default HistoryItemButton;
