import {
	CommentOutlined,
	EyeOutlined,
	LikeOutlined,
	LinkOutlined,
	TagOutlined,
} from '@ant-design/icons';
import { Flex, List, Tooltip, Typography } from 'antd';
import cn from 'classnames';

import FeedBackButton from '@/components/common/FeedBackButton';
import SentimentItem from '@/components/common/SentimentItem';
import VideoPlayer from '@/components/common/VideoPlayer';
import placeholderImg from '@/lib/assets/placeholder.png';
import { formatNumber } from '@/lib/helpers/formatNumber';
import { formatSecondsToTime } from '@/lib/helpers/formatSecondsToTime';
import prepareDate from '@/lib/helpers/prepareDate';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	data?: any;
	activeTab: TActiveMenuTab;
	playerRef: any;
	videoSeekToTime: (seconds: number) => void;
	videoWrapperRef: any;
	item: any;
	setOpenModal: any;
};

type TVideo = typeof mockData.video;
type TVideoData = typeof mockData.videoData;
type TCdnUrlData = typeof mockData.cdnUrl | null;

export default function VideoInsights({
	activeTab,
	playerRef,
	videoSeekToTime,
	videoWrapperRef,
	item,
	setOpenModal,
}: TProps) {
	const video: TVideo = item?.video;
	const videoData: TVideoData = item?.videoData;
	const cdnUrl = item?.cdnUrl as TCdnUrlData;

	const videoDuration = item?.video?.duration
		? Number(item?.video?.duration)
		: 0;

	if (activeTab !== 'video-insight') {
		return null;
	}

	return (
		<Flex vertical gap={8} align="flex-start">
			<div className={styles.imageWrapper} ref={videoWrapperRef}>
				<>
					{cdnUrl && (
						<VideoPlayer
							playerRef={playerRef}
							preview={
								!item?.video?.thumbnail.includes('.cdninstagram.')
									? item?.video?.thumbnail
									: placeholderImg
							}
							url={cdnUrl}
							// isInstagramCdn={item?.video?.thumbnail.includes('.cdninstagram.')}
							// hasImg={!item?.video?.thumbnail}
						/>
					)}
					{!cdnUrl && (
						<>
							{item?.video?.thumbnail &&
							!item?.video?.thumbnail.includes('.cdninstagram.') ? (
								<img
									src={
										item?.video?.thumbnail?.startsWith('http')
											? item?.video?.thumbnail
											: prepareImageSrc(item?.video?.thumbnail)
									}
									alt=""
								/>
							) : (
								<img
									src={placeholderImg}
									width={130}
									height={124}
									alt="'Image not found' placeholder"
								/>
							)}
						</>
					)}
				</>
			</div>

			<>
				<Flex style={{ width: '100%' }} align="center" justify="space-between">
					<Typography.Title
						level={4}
						className={styles.tabTitle}
						style={{ width: '100%' }}
					>
						<span className={styles.underline}>Video insights</span>
					</Typography.Title>
					<FeedBackButton setOpenModal={setOpenModal} />
				</Flex>

				{video?.title && (
					<Typography.Text>
						<b>Title:</b> {video?.title}
					</Typography.Text>
				)}

				{video?.description && video?.description !== video?.title && (
					<Typography.Text>
						<b>Description:</b> {video?.description}
					</Typography.Text>
				)}

				{video?.duration && (
					<Typography.Text>
						<b>Duration:</b>{' '}
						{formatSecondsToTime(
							video?.duration?.includes('.')
								? Number(video?.duration?.split('.')?.[0])
								: Number(video?.duration),
							true,
						)}
					</Typography.Text>
				)}

				<Flex
					gap={40}
					justify="flex-start"
					align="center"
					className={styles.statsWrapper}
				>
					<Typography.Title level={4} className={styles.statItemTitle}>
						<Tooltip title="Views">
							<EyeOutlined
								className={cn(
									styles.metaIcon,
									video?.stats?.views >= 0 && styles.available,
								)}
							/>
						</Tooltip>
						<span
							className={cn(
								styles.metaText,
								video?.stats?.views >= 0 && styles.available,
							)}
						>
							{video?.stats?.views >= 0
								? formatNumber(video?.stats?.views)
								: 'n/a'}
						</span>
					</Typography.Title>
					<Typography.Title level={4} className={styles.statItemTitle}>
						<Tooltip title="Comments">
							<CommentOutlined
								className={cn(
									styles.metaIcon,
									video?.stats?.comments >= 0 && styles.available,
								)}
							/>
						</Tooltip>{' '}
						<span
							className={cn(
								styles.metaText,
								video?.stats?.comments >= 0 && styles.available,
							)}
						>
							{video?.stats?.comments >= 0
								? formatNumber(video?.stats?.comments)
								: 'n/a'}
						</span>
					</Typography.Title>
					<Flex className={styles.statItemTitle}>
						<Tooltip title="Likes">
							<LikeOutlined
								style={{ fontSize: '20px' }}
								className={cn(
									styles.metaIcon,
									video?.stats?.likes >= 0 && styles.available,
								)}
							/>
						</Tooltip>
						<span
							className={cn(
								styles.metaText,
								video?.stats?.likes >= 0 && styles.available,
							)}
						>
							{video?.stats?.likes >= 0
								? formatNumber(video?.stats?.likes)
								: 'n/a'}
						</span>
					</Flex>
				</Flex>

				<Flex className={cn(styles.statItemTitle, styles.flexStart)}>
					<Tooltip title="Hashtags">
						<TagOutlined
							style={{ fontSize: '20px' }}
							className={cn(
								styles.metaIcon,
								video?.hashtags?.length > 0 && styles.available,
							)}
						/>
					</Tooltip>

					<span
						className={cn(
							styles.metaText,
							styles.small,
							styles.tags,
							video?.hashtags?.length > 0 && styles.available,
						)}
					>
						{video?.hashtags?.length > 0
							? video?.hashtags
									?.map((item: any) =>
										item?.startsWith('#') ? `${item} ` : `#${item} `,
									)
									?.join(' ')
							: 'n/a'}
					</span>
				</Flex>

				<Flex className={styles.statItemTitle}>
					<Tooltip title="Mentions">
						<span
							style={{ fontSize: '20px' }}
							className={cn(
								styles.mentionSign,
								video?.mentions?.length > 0 && styles.available,
							)}
						>
							@
						</span>
					</Tooltip>
					<span
						className={cn(
							styles.metaText,
							styles.small,
							styles.mentions,
							video?.mentions?.length > 0 && styles.available,
						)}
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: '8px',
							lineHeight: '215%',
						}}
					>
						{video?.mentions?.length > 0 ? (
							video?.mentions?.map((mention: any, idx: number) => {
								const [key, value] = Object.entries(mention ?? {})?.[0];

								if (!value) {
									return null;
								}

								return (
									<a href={value as string} target="_blank" key={key + idx}>
										{key}
									</a>
								);
							})
						) : (
							<span className={styles.thin}>n/a</span>
						)}
					</span>
				</Flex>

				<Flex
					className={cn(styles.statItemTitle, styles.flexStart)}
					style={{ width: '100%' }}
				>
					<Tooltip title="Links">
						<LinkOutlined
							style={{ fontSize: '20px' }}
							className={cn(
								styles.metaIcon,
								video?.links?.length > 0 && styles.available,
							)}
						/>
					</Tooltip>
					{video?.links?.length > 0 ? (
						<ul className={styles.linkList}>
							{video?.links?.map((link: any, idx: number) => (
								<li key={link + idx} className={styles.linkListItem}>
									<a href={link} target="_blank">
										{link}
									</a>
								</li>
							))}
						</ul>
					) : (
						<span className={cn(styles.metaText, styles.small)}>n/a</span>
					)}
				</Flex>
			</>
			<>
				{videoData?.Sentiment && (
					<Flex vertical style={{ width: '100%', marginTop: '1rem' }}>
						<Flex
							style={{ width: '100%' }}
							align="center"
							justify="space-between"
						>
							<Typography.Title
								level={4}
								className={styles.tabTitle}
								style={{ width: '100%' }}
							>
								<span className={styles.underline}>Sentiments</span>
							</Typography.Title>
							<FeedBackButton setOpenModal={setOpenModal} />
						</Flex>
						{videoData?.Sentiment?.Summary ? (
							<Typography.Text>{videoData?.Sentiment?.Summary}</Typography.Text>
						) : (
							<Typography.Text style={{ color: 'gray' }}>n/a</Typography.Text>
						)}
						{/* 
              {videoData?.Sentiment?.Examples && (
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    marginTop: "0.5rem",
                  }}
                >
                  <PieChart data={videoData?.Sentiment} />
                </div>
              )} */}

						{videoData?.Sentiment?.Examples?.length > 0 && (
							<List
								itemLayout="horizontal"
								className={styles.keyInsights}
								dataSource={item?.videoData?.Sentiment?.Examples ?? []}
								renderItem={(sentimentItem: any) => (
									<SentimentItem
										item={sentimentItem}
										videoSeekToTime={videoSeekToTime}
										videoDuration={videoDuration}
									/>
								)}
							/>
						)}

						{item?.creationDate && (
							<Typography.Text style={{ paddingTop: '1rem' }}>
								<b>Data analyzed:</b> {prepareDate(item?.creationDate)}
							</Typography.Text>
						)}
					</Flex>
				)}
			</>
		</Flex>
	);
}
