// import ShareLinkNotification from "@common/ShareLinkNotification";
// import { useShareAnalysis } from "@api/queries";
import { useEffect } from 'react';

import { LoadingOutlined, ShareAltOutlined } from '@ant-design/icons';
// import { useTranslation } from "react-i18next";
import { Button, Tooltip, message, notification } from 'antd';

import { useShareAnalysis } from '@/api/batch_process';

import styles from './style.module.css';

import ShareLinkNotification from './ShareLinkNotification';

interface IResponseError {
	response: {
		data: {
			message: string;
			error: string;
		};
	};
	message: string;
}

const ShareButton = ({
	url,
	disabled = false,
	placement = 'top',
}: {
	url: string;
	disabled?: boolean;
	placement?: 'top' | 'left' | 'right' | 'bottom';
}) => {
	// const { t } = useTranslation();
	const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
	const [messageApi] = message.useMessage();

	const { mutate, isLoading, isSuccess, isError, error, data } =
		useShareAnalysis();

	const openNotification = (pauseOnHover: boolean) =>
		api.success({
			message: 'Report link generated',
			description:
				"Here's the report you can share with your colleagues and clients!",
			duration: 10,
			showProgress: true,
			pauseOnHover,
			btn: <ShareLinkNotification data={data} />,
		});

	const onShareClick = async () => {
		messageApi.open({
			type: 'loading',
			content: 'Preparing share link..',
			duration: 0,
		});

		mutate({
			userId: Number(localStorage?.getItem('sv_extension_uid')),
			url,
		});
	};

	useEffect(() => {
		if (isSuccess) {
			openNotification(true);
			messageApi.destroy();
		}

		if (isError) {
			const shareError = error as IResponseError;
			messageApi.destroy();

			if (shareError?.response?.data?.error) {
				message.error(
					`"We have encountered an error on sharing this video": ${shareError?.response?.data?.error}`,
				);
			} else {
				message.error(
					'We have encountered an error on sharing this video. Please try again.',
				);
			}
		}
	}, [isError, isSuccess]);

	return (
		<>
			{contextHolder}
			<Tooltip title={'Share'} mouseEnterDelay={1} placement={placement}>
				<Button
					// variant="outlined"
					size="middle"
					// size="small"
					onClick={onShareClick}
					style={{ fontSize: '18px', padding: '6px', height: 'unset' }}
					className={styles.eyeHover}
					disabled={disabled || isLoading}
					// loading={isLoading}
				>
					{isLoading ? <LoadingOutlined /> : <ShareAltOutlined />}
				</Button>
			</Tooltip>
		</>
	);
};

export default ShareButton;
