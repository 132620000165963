const ONE_HOUR_IN_SECONDS = 60 * 60;

export default function prepareTimestampStr(
	videoDuration: number,
	timestampStr: string,
) {
	if (videoDuration < ONE_HOUR_IN_SECONDS && timestampStr) {
		return timestampStr?.slice(3);
	}

	return timestampStr;
}
