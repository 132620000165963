import { useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Flex, List } from 'antd';

import prepareDate from '@/lib/helpers/prepareDate';

import TranscriptionText from '../../TranscriptionText';

type TProps = {
	item: {
		question: string;
		answer: string;
		created_at: string;
	};
};

export default function QAHistoryCollapsibleItem({ item }: TProps) {
	const [collapsed, setCollpased] = useState(true);

	const dateStr = item?.created_at ? (
		<small style={{ color: '#00000073' }}>
			{prepareDate(item?.created_at?.toString())}
		</small>
	) : null;

	return (
		<List.Item>
			<List.Item.Meta
				title={
					<span
						style={{
							color: '#000',
							fontWeight: 600,
						}}
					>
						{item?.question}
						{item?.answer && (
							<Button
								type="text"
								size="small"
								icon={collapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
								onClick={() => setCollpased((cur) => !cur)}
								style={{ marginLeft: '0.5rem' }}
								aria-label={'Toggle answer'}
							></Button>
						)}
					</span>
				}
				description={
					collapsed ? (
						<>{dateStr}</>
					) : (
						<Flex vertical gap={8} style={{ color: '#000' }}>
							<TranscriptionText text={item?.answer} searchTerm="" />
							{dateStr}
						</Flex>
					)
				}
			/>
		</List.Item>
	);
}
