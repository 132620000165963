import React, { useContext, useEffect, useState } from 'react';

import { AntDesignOutlined, SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	Alert,
	Anchor,
	Avatar,
	Button,
	Card,
	Divider,
	Modal,
	Spin,
} from 'antd';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { domToCanvas, domToImage, domToPng } from 'modern-screenshot';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import ErrorBoundary from '@/components/errors/boundary';
import ErrorBoundaryReport from '@/components/errors/report-section-boundary';
import { fetchReportData } from '@/interfaces/report-data';
import client from '@/lib/http/client';
import NotFound from '@/pages/404';

import style from './style.module.css';

import { InfoSection } from './informationPopop';
import { generatePDFClick } from './pdf';
import ReportEmotionalVoice from './platform-emotional-voice';
import ReportBrandMentions from './report-brand-mentions';
import BrandSafety from './report-brand-safety';
import ReportContent from './report-content';
import ReportSummary from './report-summary';
import ReportPlatformVideos from './report-videos';
import { IReport } from './report.types';

const CreatorReport = () => {
	const { subscriptions, subscriptionLoading } =
		useContext(SubscriptionContext);
	const [loading, setLoading] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [influencerName, setinfluencerName] = useState('');

	const [percent, setPercent] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		if (!subscriptionLoading && subscriptions && subscriptions?.length > 0) {
			const hasIIRSubscription = subscriptions.some((sub) =>
				sub?.subscription_name?.toLowerCase()?.includes('integrity report'),
			);

			if (!hasIIRSubscription) {
				navigate('/app/dashboard/product-main-menu');
			}
		} else if (
			!subscriptionLoading &&
			subscriptions &&
			subscriptions?.length === 0
		) {
			navigate('/app/dashboard/product-main-menu');
		}
	}, [subscriptions, subscriptionLoading]);

	// Convert HTML to PDF
	const handlePDFButtonClick = async () => {
		setPdfLoading(true);
		mutate();
	};
	const generatePDF = () => {
		return client
			.post(
				`report-pdf/generatePdf`,
				{
					url: window.location.href,
				},
				{
					responseType: 'arraybuffer',
				},
			)
			.then((response) => response);
	};

	const { isLoading: isPdloading, mutate } = useMutation({
		mutationFn: generatePDF,
		onSuccess: (response) => {
			setPdfLoading(false);
			const link = document.createElement('a');
			let url = '';
			if (
				response.headers['content-type'] === 'application/json; charset=utf-8'
			) {
				const decoder = new TextDecoder('utf-8');
				const jsonResponse = JSON.parse(
					decoder.decode(new Uint8Array(response.data)),
				);

				url = `${process.env.REACT_APP_CDN_STORAGE}${jsonResponse.url}`;
				link.setAttribute('target', '_blank');
			} else {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				url = URL.createObjectURL(blob);
			}

			link.href = url;
			link.download = `sv_report.pdf`;
			// link.download = `${data?.data.creator.creatorName}_report.pdf`;
			link.setAttribute('download', `report_${influencerName}.pdf`);
			document.body.appendChild(link);

			link.click();

			document.body.removeChild(link);
		},
		onError: (error) => {
			setPdfLoading(false);
		},
	});

	const { id = '' } = useParams<{ id: string }>();
	const { data, isLoading, status, error } = useQuery<IReport, AxiosError<any>>(
		{
			queryKey: ['reportData', id],
			queryFn: () => fetchReportData(id),
			enabled: !!id,
			retry: 2,
		},
	);
	React.useEffect(() => {
		const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
		if (hash) {
			const element = document.getElementById(hash);
			if (element) {
				element.scrollIntoView({
					//  behavior: 'smooth'
				});
			}
		}
	}, []);
	React.useEffect(() => {
		if (data?.data?.creator?.creatorName) {
			setinfluencerName(
				data?.data?.creator?.creatorName.toLowerCase().replace(/\s/g, ''),
			);
		}
	}, [isLoading]);

	if (isLoading) {
		return (
			<Spin spinning={isLoading}>
				<div className={style.header}></div>
				<div className={style.title}>
					<h1 className={style.link}>Influencer Integrity Report</h1>

					<Link to={`/app/dashboard/creators`}>
						<Button>Back</Button>
					</Link>
				</div>
				<div className={style.layout}></div>
			</Spin>
		);
	}

	if (error?.response?.data?.message === 'Forbidden') {
		return <div>Forbidden</div>;
	}

	if (!isLoading && !data?.data) {
		return <NotFound />;
	}

	return (
		<Spin spinning={isLoading}>
			<Spin percent={percent} spinning={pdfLoading} fullscreen size="large" />
			<div className={style.header}></div>
			<div className={style.title}>
				<h1 className={style.link} id="sytetitle">
					Influencer Integrity Report
				</h1>
				<div>
					{data?.data?.pdfurl ? (
						<a
							href={`${process.env.REACT_APP_CDN_STORAGE}${data?.data?.pdfurl}`}
							download
							target="_blank"
						>
							<Button id="download-pdf-button" style={{ marginRight: '10px' }}>
								Download PDF
							</Button>
						</a>
					) : (
						<Button
							id="generate-pdf-button"
							onClick={handlePDFButtonClick}
							style={{ marginRight: '10px' }}
						>
							<Spin spinning={loading}>Download PDF</Spin>
						</Button>
					)}

					<Button onClick={() => navigate(-1)}>Back</Button>
				</div>
			</div>

			<div className={style.layout} id="title">
				<div className={style.avatar}>
					<Avatar
						size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
						icon={<AntDesignOutlined />}
						src={`${process.env.REACT_APP_CDN_STORAGE}${data?.data?.creator?.profileImageURL}`}
					/>
				</div>
				<div className={style.creatorHeader}>
					<span className={style.creatorName}>
						{data?.data?.creator?.creatorName}
					</span>
				</div>
			</div>

			<Divider />
			<div className={style.layout}>
				<Anchor
					style={{ padding: '2rem 0' }}
					items={[
						{
							key: 'part-1',
							href: '#summary',
							title: 'Summary',
						},
						{
							key: 'brand-safety',
							href: '#brand-safety',
							title: 'Brand Safety',
						},
						{
							key: 'content',
							href: '#content',
							title: 'Content',
						},
						{
							key: 'brand-mentions',
							href: '#brand-mentions',
							title: 'Entity Mentions',
						},

						{
							key: 'emotional-voice',
							href: '#emotional-voice',
							title: 'Emotional Voice',
						},
						{
							key: 'part-2',
							href: '#headliner-videos',
							title: 'Most Recent Videos',
						},
						{
							key: 'engagement',
							href: '#engagement',
							title: 'Top 15 Videos',
						},
					]}
				/>

				{data?.data?.status?.code === 'ERR_NO_VIDEOS' ? (
					<div>
						<Alert
							message="Error"
							description={data?.data?.status?.message}
							type="error"
							showIcon
						/>
					</div>
				) : (
					<div className={style.report} id="pdf">
						<div id="summary" className={style.reportSection}>
							<ErrorBoundaryReport
								message={data?.data?.errors || {}}
								topics={['creator', 'metrics', 'followersPercents', 'report']}
							>
								<ReportSummary
									id={id}
									data={data?.data}
									report_id={data?.report_id}
									errors={data?.data?.errors}
								/>
							</ErrorBoundaryReport>
						</div>
						<div id="brand-safety" className={style.reportSection}>
							<h3 className={style.link}>
								Brand Safety
								<InfoSection
									title="GARM Brand Safety Floor Analysis"
									content={
										<>
											<p>
												The Brand Safety Floor standard has been defined by GARM
												as a tool that brands and advertisers can use to ensure
												that the content they are engaging with is brand safe,
												or not. Our highly detailed brand safety analysis
												analyses content produced by the account over twelve
												different categories and identifies any that may be
												considered when aiming to minimise risk in a commercial
												relationship.
											</p>
										</>
									}
								/>
							</h3>
							<ErrorBoundaryReport
								message={data?.data?.errors || {}}
								topics={['brandSafety']}
							>
								<BrandSafety data={data?.data} />
							</ErrorBoundaryReport>
						</div>
						<div id="content" className={style.reportSection}>
							<h3 className={style.link}>Content Topic Analysis</h3>
							<ErrorBoundaryReport
								className="unique-error-boundary-report"
								style={{
									backgroundColor: 'transparent',
									color: 'black',
								}}
								message={data?.data?.errors || {}}
								topics={['topicDriftWordClouds', 'iabSunburst', 'iabBarChart']}
							>
								<ReportContent id={id} data={data?.data} />
							</ErrorBoundaryReport>
						</div>

						<div id="brand-mentions" className={style.reportSection}>
							<h3 className={style.link}>Entities Mentioned</h3>
							<ErrorBoundaryReport
								className="unique-error-boundary-report"
								style={{
									backgroundColor: 'transparent',
									color: 'black',
								}}
								message={data?.data?.errors || {}}
								topics={['brandMentionSummary', 'brandMentionBarChart']}
							>
								<ReportBrandMentions data={data?.data} />
							</ErrorBoundaryReport>
						</div>
						<div id="emotional-voice" className={style.reportSection}>
							<h3 className={style.link}>
								Spoken Word and Vocal Analysis
								<InfoSection
									title="Spoken Word and Vocal Analysis"
									content="Spoken word and vocal analysis
							SocialVoice analyses the words used by the account over all video platforms monitored. We use this analysis to determine how the words used fit into different categories. This helps to understand the consistency of voice and how this account communicates with their audience."
								/>
							</h3>
							<ErrorBoundaryReport
								message={data?.data?.errors || {}}
								topics={[
									'personalityTypeChart',
									'focusAndToneSummary',
									'soundAnalysisChart',
								]}
							>
								<ReportEmotionalVoice data={data?.data} />
							</ErrorBoundaryReport>
						</div>
						<div id="headliner-videos" className={style.reportSection}>
							<h3 className={style.link}>
								Most Recent Videos
								<InfoSection
									title="Most Recent Videos"
									content="This is a snapshot of the most recent videos generated by the account across their main platforms. Each video has a click-through link and highlights the number of views and engagement count/type."
								/>
							</h3>
							<ErrorBoundaryReport
								message={data?.data?.errors || {}}
								topics={['latest15']}
							>
								<ReportPlatformVideos id={id} videos={data?.data?.latest15} />
							</ErrorBoundaryReport>
						</div>
						<div id="engagement" className={style.reportSection}>
							<h3 className={style.link}>
								Top 15 Videos
								<InfoSection
									title="Top Performing Videos all time"
									content="Top Performing videos all time
							A list of the top performing videos from all platforms that have performed the best for the selected account. Performance is indicated by number of views and engagement. These are from our database of processed videos, and may be outside of the report date range."
								/>
							</h3>
							<ErrorBoundaryReport
								message={data?.data?.errors || {}}
								topics={['top15']}
							>
								<ReportPlatformVideos id={id} videos={data?.data?.top15} />
							</ErrorBoundaryReport>
						</div>
					</div>
				)}
			</div>
		</Spin>
		// </Spin>
	);
};

export default CreatorReport;
