import { useState } from 'react';

import { Flex, Table, Tabs } from 'antd';

import { TActiveMenuTab } from '../../..';
import QAHistoryTab from './QAHistoryTab';
import QuestionTab from './QuestionTab';

type TProps = {
	item: any;
	activeTab: TActiveMenuTab;
};

export default function QandA({ activeTab, item }: TProps) {
	const [activeKeyTab, setActiveKeyTab] = useState<'question' | 'history'>(
		'question',
	);

	if (activeTab !== 'questions-answers') {
		return null;
	}

	return (
		<Flex
			vertical
			gap={16}
			align="flex-start"
			style={{ paddingLeft: '0.5rem' }}
		>
			<Tabs
				style={{ marginTop: '-0.5rem', width: '100%' }}
				defaultActiveKey="question"
				activeKey={activeKeyTab}
				onChange={(key) => setActiveKeyTab(key as any)}
				items={[
					{
						key: 'question',
						label: 'Question',
						children: <QuestionTab item={item} />,
					},
					{
						key: 'history',
						label: 'History',
						children: <QAHistoryTab item={item} activeKey={activeKeyTab} />,
					},
				]}
			/>
		</Flex>
	);
}
