import { useState } from 'react';

import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';

import { copyTextToClipboard } from '../../creators/helper';

// TODO: update from env
const COPILOT_SHARE_URL = 'https://dev.socialvoice.ai/share/';

type TProps = {
	data: any;
};

export default function ShareLinkNotification({ data }: TProps) {
	const [isLinkCopied, setIsLinkCopied] = useState(false);
	let linkTimerId: any;

	const preparedShareLinkUrl = COPILOT_SHARE_URL?.endsWith('/')
		? `${COPILOT_SHARE_URL}${data?.data?.data}`
		: `${COPILOT_SHARE_URL}/${data?.data?.data}`;

	return (
		<Flex align="center" gap={16}>
			{data?.data?.data && (
				<Link href={preparedShareLinkUrl} target="_blank">
					{preparedShareLinkUrl}
				</Link>
			)}

			<Tooltip title="Copy link">
				<Button
					style={{ minWidth: '32px' }}
					type="primary"
					//   size="small"
					icon={isLinkCopied ? <CheckOutlined /> : <CopyOutlined />}
					onClick={async () => {
						await copyTextToClipboard(preparedShareLinkUrl);

						// console.log("copied");

						setIsLinkCopied(true);

						linkTimerId = setTimeout(() => setIsLinkCopied(false), 2000);
					}}
					aria-label="Copy link"
				/>
			</Tooltip>
		</Flex>
	);
}
