import { useContext, useEffect } from 'react';

import { Button, Drawer, Flex, List, Skeleton, Space, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import { SubscriptionContext } from '@/App';
import { useGetProductSubscription } from '@/api/customer_subscription';
import { productItems } from '@/layouts/subscription/NewProductSubscription/data';
import SubscriptionCartFormItem from '@/layouts/subscription/SubscriptionCartFormItem';
import SubscriptionCartFormItemVariants from '@/layouts/subscription/SubscriptionCartFormItemVariants';
import { subscription } from '@/layouts/subscription/subscriptionType';
import UnsubscribeModal from '@/layouts/subscription/unsubscribeModal';
import { PageContext } from '@/providers/PageContextProvider';

export default function ViewProductSubscriptionDrawer() {
	const {
		drawer,
		closeDrawer,
		selectedSubscriptionsType,
		modal,
		closeModal,
		openModal,
		isModalOpen,
	} = useContext(PageContext);

	const { subscriptions, unsubscribeSubscription } =
		useContext(SubscriptionContext);

	const { data, isError, error, isLoading } = useGetProductSubscription(
		drawer?.data?.subscriptionName,
	);

	const onClose = () => {
		closeDrawer();
	};

	const productItem = productItems.find((item) => item.id === drawer?.data?.id);

	const activeSubscription = selectedSubscriptionsType?.find(
		(subscription: subscription) =>
			subscriptions?.some(
				(current) =>
					current.subscription_name === subscription.subscription_name &&
					current.status === 'active',
			),
	);

	const subscriptionName =
		typeof data?.data?.[0]?.subscription_name === 'string'
			? data?.data?.[0]?.subscription_name
			: '';
	const features =
		typeof data?.data?.[0]?.features === 'string'
			? data?.data?.[0]?.features?.split('\n')
			: [];

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-product-subscription'}
			width={'650px'}
			onClose={onClose}
			closable={false}
			maskClosable={true}
			loading={isLoading}
			title={
				<Flex gap={16} align="center">
					{isLoading ? (
						<Skeleton.Image active />
					) : (
						<img
							// TODO: update + remove hardcoded images
							src={data?.data?.[0]?.product_image ?? productItem?.image}
							alt="Product image"
							width={120}
							height={'auto'}
							style={{
								borderRadius: '10px',
								overflow: 'hidden',
								maxHeight: '120px',
								objectFit: 'cover',
							}}
						/>
					)}
					<Typography.Title level={2}>
						{productItem?.subscriptionName}
					</Typography.Title>
				</Flex>
			}
		>
			<Flex justify="flex-end">
				<Space>
					<Button onClick={onClose}>Close</Button>
					{/* <Link href="https://www.socialvoice.ai" target="_blank">
						<Button type="primary" disabled={isAlreadyInSubscriptions}>
							{isAlreadyInSubscriptions ? 'Already subscribed' : 'Buy now'}
						</Button>
					</Link> */}
				</Space>
			</Flex>

			<Flex vertical gap={10} align="stretch" justify="flex-start">
				<Typography.Title level={5} style={{ marginBottom: 0 }}>
					Description
				</Typography.Title>
				<Typography.Text>{data?.data?.[0]?.description}</Typography.Text>
				{features?.length > 0 && (
					<>
						<Typography.Title level={5} style={{ marginBottom: 0 }}>
							Features
						</Typography.Title>
						<List
							size="small"
							itemLayout="horizontal"
							dataSource={features}
							renderItem={(item, index) => (
								<List.Item key={`feature-${index}`}>
									<List.Item.Meta
										avatar="📌"
										title={
											<span style={{ fontWeight: 400 }}>{item as string}</span>
										}
									/>
								</List.Item>
							)}
						/>
					</>
				)}
			</Flex>
			{selectedSubscriptionsType
				.sort((a, b) => {
					if (a.per_month_eur > b.per_month_eur) return 1;
					if (a.per_month_eur < b.per_month_eur) return -1;
					return 0;
				})
				?.map((subscription: subscription) => {
					const isActive = subscriptions
						? subscriptions.find(
								(current) =>
									current.subscription_name === subscription.subscription_name,
						  )?.status === 'active'
						: false;

					// const isAnySubscribeActive = true;
					const isAnySubscribeActive = subscriptions
						? subscriptions.some(
								(current) =>
									current.type === subscription.type &&
									current.status === 'active',
						  )
						: false;

					return (
						<SubscriptionCartFormItemVariants
							key={subscription.id}
							subscription={subscription}
							index={subscription.id}
							subscribe={!isActive}
							subscriptionId={subscription.id}
							isAnySubscribeActive={isAnySubscribeActive}
							activeSubscription={activeSubscription}
							matchedSubscription={
								subscriptions
									? subscriptions.find(
											(current) =>
												current.subscription_name ===
												subscription.subscription_name,
									  )
									: undefined
							}
						/>
					);
				})}
		</Drawer>
	);
}
