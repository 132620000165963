import {
	CheckCircleOutlined,
	ExclamationCircleOutlined,
	LoadingOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Flex, Space, Tooltip, Typography } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import Link from 'antd/es/typography/Link';

import { CopilotHistoryPageItem } from '@/api/batch_process';
import placeholderImage from '@/lib/assets/placeholder.png';
import prepareDate from '@/lib/helpers/prepareDate';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import styles from './style.module.css';

import Platforms from '../creators/platforms';

const columns = [
	{
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return <p title={'' + record?.id}>{record.title}</p>;
		},
	},
	{
		title: 'Date/time processed',
		dataIndex: 'requested_at',
		key: 'requested_at',
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 60,
		// flex: true,
		// ellipsis: true,
		// hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return (
				<>
					{record?.createdAt ? prepareDate('' + record?.createdAt) : 'no date'}
				</>
			);
		},
	},
	{
		title: 'Status / Title',
		dataIndex: 'title',
		key: 'title',
		width: 200,
		ellipsis: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			const preparedTitle =
				(record?.title || record?.description)?.length > 90
					? (record?.title || record?.description).slice(0, 90) + '...'
					: record?.title || record?.description;

			let IconComponent = null;

			switch (record?.status) {
				case 0:
				case undefined:
					IconComponent = (
						<Tooltip title={'Adding to queue...'}>
							<LoadingOutlined
								style={{ color: 'gray' }}
								// className={styles.indicatorIcon}
							/>
						</Tooltip>
					);
					break;

				case 1:
					IconComponent = (
						<Tooltip title={'Processing...'}>
							<SyncOutlined
								spin
								style={{
									color: '#172b6f',
								}}
								// className={styles.indicatorIcon}
							/>
						</Tooltip>
					);
					break;

				case 2:
					IconComponent = (
						<Tooltip title={'Analysis complete'}>
							<CheckCircleOutlined
								style={{
									color: '#58C622',
								}}
								// className={styles.indicatorIcon}
							/>
						</Tooltip>
					);
					break;

				case 3:
				case 4:
				case 5:
					IconComponent = (
						<Tooltip title={'We experienced an issue analysing this video.'}>
							<ExclamationCircleOutlined
								style={{
									color: 'orange',
								}}
								// className={styles.indicatorIcon}
							/>
						</Tooltip>
					);
					break;

				default:
					IconComponent = (
						<Tooltip title={'Adding to queue...'}>
							<LoadingOutlined
								style={{ color: 'gray' }}
								// className={styles.indicatorIcon}
							/>
						</Tooltip>
					);
					break;
			}

			return (
				<Space size={[8, 1]}>
					{/* <Avatar
						src={
							record?.thumbnail
								? prepareImageSrc(record?.thumbnail)
								: placeholderImage
						}
						alt={`${record?.platform} avatar`}
					/>{' '} */}
					{IconComponent}
					<img
						src={
							record?.thumbnail
								? prepareImageSrc(record?.thumbnail)
								: placeholderImage
						}
						alt="thumbnail"
						// width={120}
						// height={90}
						style={{
							maxWidth: '120px',
							objectFit: 'cover',
							maxHeight: '100px',
							backgroundColor: '#eee',
						}}
					/>
					<Link href={record?.url} ellipsis target="_blank">
						{preparedTitle}
					</Link>
				</Space>
			);
		},
	},
	{
		title: 'Platform / Creator',
		dataIndex: 'platform',
		key: 'platform',
		// sorter: true,
		elipsis: true,
		width: 60,
		// flex: true,
		// hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return (
				<Flex justify="flex-start" align="center" gap={8}>
					<Platforms platform={record?.platform} />

					{record?.creatorUrls?.[0] ? (
						<Button
							type="link"
							href={record?.creatorUrls?.[0]}
							target="_blank"
							style={{
								width: 'fit-content',
								display: 'flex',
								wordWrap: 'break-word',
								overflow: 'hidden',
								padding: 0,
							}}
						>
							<span
								style={{
									width: '100%',
									display: 'inline-block',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{record?.creatorName}
							</span>
						</Button>
					) : (
						<>
							{record?.creatorName ? (
								<Typography.Text
									style={{
										width: 'auto',
										display: 'inline-block',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{record?.creatorName}
								</Typography.Text>
							) : null}
						</>
					)}
				</Flex>
			);
		},
	},
];

export { columns };
