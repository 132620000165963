import React, { useEffect, useState } from 'react';

import { LoadingOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
// import { useTranslation } from "react-i18next";
import cn from 'classnames';

import { useAddRemoveFromFavorites } from '@/api/batch_process';

// import { useAddRemoveFromFavorites } from "@api/queries";
import styles from './style.module.css';

// import { IError } from "@typescript/types";
type IError = {
	message: string;
	status: number;
	code: string;
	response: {
		data: {
			err: string;
			message: string;
		};
	};
};

const SwitchFavourites = ({
	isFavorite = false,
	id,
	disabled = false,
}: {
	isFavorite: boolean;
	id: string;
	disabled?: boolean;
}) => {
	const [favourite, setFavourite] = useState(isFavorite);

	const {
		mutate,
		isSuccess,
		isError: updateIsError,
		error: updateError,
		isLoading,
	} = useAddRemoveFromFavorites();

	const toggleIcon = (value: boolean) => {
		mutate({
			id: id,
			isAdd: !value,
		});
	};

	useEffect(() => {
		if (updateIsError) {
			const err = updateError as IError;

			message.error(
				`Error on sharing: ${err?.response?.data?.err || err?.message}`,
			);
			return;
		}
		if (isSuccess) {
			setFavourite((cur) => !cur);
		}
	}, [updateIsError, isSuccess]);

	// useEffect(() => {
	//   if (isActive !== isFavorite) {
	//     refetch();
	//   }
	// }, [isActive, isFavorite, refetch]);

	return (
		<Tooltip
			title={favourite ? 'Remove from favourites' : 'Add to favourites'}
			mouseEnterDelay={1}
		>
			<Button
				// variant="outlined"
				size="middle"
				// size="small"
				onClick={() => toggleIcon(favourite)}
				style={{ fontSize: '18px', padding: '6px', height: 'unset' }}
				className={cn(styles.starHover, favourite && styles.starHoverActive)}
				disabled={disabled || isLoading}
			>
				{isLoading ? (
					<LoadingOutlined />
				) : favourite ? (
					<StarFilled
					// style={{ color: "#04bbeb" }}
					// className={styles.starHoverActive}
					/>
				) : (
					<StarOutlined
					// style={{ color: "gray" }}
					// className={styles.starHover}
					/>
				)}
				{/* {} */}
			</Button>
		</Tooltip>
	);
};

export default SwitchFavourites;
